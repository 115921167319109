import React, { useState, useEffect, useContext } from "react";
import { makeRequest } from "../Global/makeRequest";
import { Modal, Container, Table, Row, Col, Button, FormControl, Form, FloatingLabel} from 'react-bootstrap'
import {PlusLg, DashLg} from 'react-bootstrap-icons'
import Find from "../Global/Find";
import toast, {Toaster} from 'react-hot-toast';
import { AuthContext } from "../Auth/authContext";
import { useNavigate } from "react-router-dom";
import { HandleLogout } from "../Global/Logout";

function Calendario() {
    const [currentUser, setCurrentUser] = useState(null);
    const [struttura, setStruttura] = useState(0);
    const [modalModify, showModalModify] = useState(false);
    const [modalAdd, showModalAdd] = useState(false);
    const [modalDay, showModalDay] = useState(false);
    const [modalTer, showModalTer] = useState(false);
    const [listaFind, setListaFind] = useState([]);
    const [tab, setTab] = useState(-1);
    const [find, showFind] = useState(false);
    const [search, setSearch] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [finded, setFinded] = useState(false);
    const [retrieve, setRetrieve] = useState([]);
    const [errore, setErrore] = useState('');
    const [regimeselect, setRegime] = useState("0");
    const navigate = useNavigate();
    const { logout } = useContext(AuthContext);
    const handleLogout = async() => {
      try {
        await logout()
        navigate('/');
      }
      catch(err) {
        console.log(err);
      }
    }
    const countPresenze = (data) => {
        let conto = {ambord: 0, domord: 0, ambrp: 0, domrp: 0}
        listapresenze.map((item) => {
            if(item.RifStruttura === struttura) {
                if(item.Data === data) {
                    if(item.Regime === 0) {
                        if(item.RifTipo === 0) {
                            conto.ambord++;
                        } else if(item.RifTipo === 1) {
                            conto.domord++;
                        }
                    } else if(item.Regime === 1) {
                        if(item.RifTipo === 0) {
                            conto.ambrp++;
                        } else if(item.RifTipo === 1) {
                            conto.domrp++;
                        }
                    }
                }
            }
            return item;
        })
        return conto;
    }
    useEffect(() => {
        if(finded !== false) {
            const lista = listaFind.filter(item => item.id === finded.id)
            const nome = lista[0]?.Nome;
            const cognome = lista[0]?.Cognome;
            const cellulare = lista[0]?.Cellulare;
            makeRequest.post('/pazienti/getfromfind/',{nome, cognome, cellulare, struttura})
            .then(res=>{
                setRetrieve(res.data)
                setInfoPaziente(res.data[0]);
            })
            .catch(err=>console.log(err))
        }
    },[finded])
    const findPaziente = (e) => {
        setSearch(prev => e.target.value);
        let value=null;
        if(e.target.value?.length) {
            value = e.target.value;
            makeRequest.post("/pazienti/find/",{value, struttura})
            .then((res) => {
                const data = res.data.map((item, index) => {
                    return {...item, id: index}
                });
                setListaFind(data);
            })
            .catch(err=>setListaFind([]))
            showFind(true);
        } else {
            setListaFind(null);
            showFind(false);
        }
    }
    const handleKey = event => {
        switch(event.key) {
            case 'ArrowUp': {
              if(listaFind?.length && tab >= 0)
                setTab(tab-1);
              break;
            }
            case 'ArrowDown': {
              if(listaFind?.length - 1 > tab)
                setTab(tab+1);
                break;
            }
            case 'Enter': {
              let id = undefined;
              listaFind.map((item, index) => {
                if(index === tab)
                  id = item.id; 
                return item;
              })
              setFinded({id: id});
              showFind(false);
              break;
            }
            default: {
              setTab(-1)
              break;
            }
        }
    }
    const handleCopyTer = () => {
        const newArr = listapresenze.filter((item) =>  item.Data == terItem.Data && item.RifTerapista == terItem.RifTerapista)
        setCopyTer(newArr)
        showModalTer(false);
        toast.success('Giornata del terapista copiata con successo');
    }
    const handlePasteTer = () => {
        setDisabled(true);
        let conto = 0;
        const data = terItem.Data;
        const terapista = terItem.RifTerapista;
        let catprima = '';
        const newArr = copyTer.map((item) => {
            if(item.Regime == 0)
                conto++;
            catprima = item.Tipo;
            return {...item, Data: data, RifTerapista: terapista}
        })
        makeRequest.post('/calendario/getsettimanali/',{datainizio, datafine, struttura, tipo})
        .then(res => {
            const settimana = res.data[0].Conto;
            if(checkMaxPresenze(conto+settimana)) {
                makeRequest.post('/terapisti/getterapista/',{terapista})
                .then(res => { 
                    const categoria = res.data[0]?.Tipo;
                    if(categoria === catprima) {
                        const terapisti = terapista;
                        makeRequest.post('/calendario/getday',{data, struttura, terapisti})
                        .then(async res => {
                            if(res.status === 200) {
                                const id = res.data.map((item) => {
                                    return item.ID;
                                })
                                makeRequest.post('/calendario/remove', {id})
                                .then(res=> {
                                    const mypromise = insertCalendario(newArr).then(() => {
                                        setUpdate(prev => !prev);    
                                        setDisabled(false);  
                                    });
                                    showModalTer(false);
                                    toast.promise(mypromise, {
                                        loading: 'Sto incollando... non chiudere la finestra',
                                        success: 'Incollato con successo',
                                        error: 'Si è verificato un errore, riprova'
                                    });            
                                })
                                .catch(err=>toast.error("Si è verificato un errore"))
                            } else if(res.status === 201) {
                                const mypromise = insertCalendario(newArr).then(() => {
                                    setUpdate(prev => !prev);    
                                    setDisabled(false);  
                                })
                                showModalTer(false);
                                toast.promise(mypromise, {
                                    loading: 'Sto incollando... non chiudere la finestra',
                                    success: 'Incollato con successo',
                                    error: 'Si è verificato un errore, riprova'
                                });
                            }//67558
                        })
                        .catch(err => console.log(err)); 
                    } else {
                        setDisabled(false);
                        return toast.error("Puoi incollare soltanto su un terapista della stessa tipologia");
                    }
                })
                .catch(err=>console.log(err))
            } else {
                setDisabled(false);
                return toast.error("Non puoi incollare qui: superi le terapie ordinarie settimanali");
            }
        })
    }
    const removeCalendario = async(newArr) => {
        return await Promise.all(
            newArr.map(async (item,index) => {
                const id = item.ID;
                const data = item.Data;
                const struttura = item.RifStruttura;
                const riftipo = item.RifTipo;
                const ora = item.Ora;
                const paziente = item.RifPaziente;
                const terapista = item.RifTerapista;
                const regime = item.Regime;
                console.log(item);
                const val = await makeRequest.post('/calendario/remove',{id})
                .then(res=>inserisciLog("Prestazione [ID:"+id+"] [Data:"+data+" "+ora+"] [Strutt:"+struttura+"] [RifTipo:"+riftipo+"] [Paz:"+paziente+"] [Ter:"+terapista+"] [Regime:"+regime+"] eliminata dal calendario"))
                .catch(err=> toast.error("Si è verificato un errore"));
                return item;
            })
        )
    }
    const handleEmptyTer = () => {
        const data = terItem.Data;
        const terapisti = terItem.RifTerapista;
        if(window.confirm("Sei sicuro di voler svuotare la giornata di questo terapista?")) {
            setDisabled(true);
            makeRequest.post('/calendario/getday',{data, terapisti, struttura})
            .then(res=> {
                const newArr = res.data;
                const mypromise = removeCalendario(newArr).then(() => {
                    setUpdate(prev => !prev);    
                    setDisabled(false);  
                })
                showModalTer(false);
                toast.promise(mypromise, {
                    loading: 'Sto svuotando... non chiudere la finestra',
                    success: 'Svuotato con successo',
                    error: 'Si è verificato un errore, riprova'
                });
            })
        }
    }
    const handleEmptyDay = () => {
        const data = dayItem;
        const terapisti = listaterapisti[0].map((item) => {
            return item.Riferimento;
        });
        if(window.confirm("Sei sicuro di voler svuotare tutta la giornata?")) {
            setDisabled(true);
            makeRequest.post('/calendario/getday',{data, terapisti, struttura})
            .then(res=> {
                const newArr = res.data;
                const mypromise = removeCalendario(newArr).then(() => {
                    setUpdate(prev => !prev);    
                    setDisabled(false);  
                })
                showModalDay(false);
                toast.promise(mypromise, {
                    loading: 'Sto svuotando... non chiudere la finestra',
                    success: 'Svuotato con successo',
                    error: 'Si è verificato un errore, riprova'
                });
            })
        }
    }
    const handleCopyDay = () => {
        const newArr = listapresenze.filter((item) => item.Data == dayItem)
        setCopyDay(newArr);
        showModalDay(false);
        toast.success('Giorno copiato con successo');
    }
    const handleCopyWeek = () => {
        setCopyWeek(listapresenze);
        setWeekItem(arrgiorni);
        toast.success('Settimana copiata con successo');      
    }
    const moveCalendario = async(newArr) => {
        return await Promise.all(
            newArr.map(async (item) => {
                const data = item.Data;
                const ora = item.Ora;
                const terapista = item.RifTerapista;
                const id = item.ID;
                const nota = "Vecchia data: "+YMD(item.Dataprima)+' '+item.Oraprima+' '+item.Userprima;
                const val = await makeRequest.put('/calendario/move',{data, ora, terapista, id, nota})
                .catch(err=> toast.error("Si è verificato un errore"));
                return item;
            })
        )
    }
    const insertCalendario = async(newArr, week = false) => {
        return await Promise.all(
            newArr.map(async (item) => {
                const data = item.Data;
                const ora = item.Ora;
                const terapista = item.RifTerapista;
                const paziente = item.RifPaziente;
                const stato = 0; 
                const regime = item.Regime;
                const categoria = item.Tipo;
                const riftipo = item.RifTipo;
                const giorno = item.Giorno;
                const id = paziente;
                let insert = true;
                if(regime == 0 && week == false) {
                    await makeRequest.post('/pazienti/checkexcess',{id})
                    .then(async(res)=>{
                        const value = res.data;
                        switch(categoria) {
                            case 'Logopedista': {
                                insert = checkPrestazioni('Logopedista', value[0]?.ILogopedica, id);
                                if(insert === false) {
                                    toast.error(value[0]?.Nome+' '+value[0]?.Cognome+': prestazioni erogate massime raggiunte per Logopedia')
                                    return item;
                                }
                                break;
                            }
                            case 'Neuromotoria':
                            case 'Fisioterapista': {
                                insert = checkPrestazioni('Fisioterapista', value[0]?.IFKT+value[0]?.INeuromotoria, id);
                                if(insert === false) {
                                    toast.error(value[0]?.Nome+' '+value[0]?.Cognome+': prestazioni erogate massime raggiunte per: Fisioterapia')
                                    return item;
                                }
                                break;
                            }
                            case 'TNPEE': {
                                insert = checkPrestazioni('TNPEE', value[0]?.IPsicomotoria, id);
                                if(insert === false) {
                                    toast.error(value[0]?.Nome+' '+value[0]?.Cognome+': prestazioni erogate massime raggiunte per: TNPEE')
                                    return item;
                                }  
                                break;
                            }
                            default: break;
                        }
                        if(insert !== false) {
                            const val = await makeRequest.put('/calendario/insert',{data, ora, terapista, paziente, stato, regime, categoria, riftipo, giorno, struttura})
                            .then(res=>inserisciLog("Prestazione inserita "+data+" "+ora+" [Reg:"+regime+"] [Ter:"+terapista+"] [Paz:"+paziente+"] [Cat:"+categoria+"] [Giorno:"+giorno+"] [RifTipo:"+riftipo+"] [Strutt:"+struttura+"]"))
                            .catch(err=> toast.error("Si è verificato un errore"));
                        }
                    })
                    .catch(err=>console.log(err))
                } else {
                    const val = await makeRequest.put('/calendario/insert',{data, ora, terapista, paziente, stato, regime, categoria, riftipo, giorno, struttura})
                    .then(res=>inserisciLog("Prestazione inserita "+data+" "+ora+" [Reg:"+regime+"] [Ter:"+terapista+"] [Paz:"+paziente+"] [Cat:"+categoria+"] [Giorno:"+giorno+"] [RifTipo:"+riftipo+"] [Strutt:"+struttura+"]"))
                    .catch(err=> toast.error("Si è verificato un errore"));
                }
                return item;
            })
            
        )
    }
    const inserisciLog = (testo) => {
        makeRequest.post('/log/insert',{testo})
        .catch(err=>console.log(err))
    }
    const checkTerapisti = (terapisti, item) => {
        for(var i = 0; i < terapisti.length; i++) {
            if(item.RifTerapista === terapisti[i])
                return true;
        }
        return false;
    }
    const handlePasteWeek  = async() => {
        const terapisti = listaterapisti[0].map((item) => {
            return item.Riferimento;
        });
        for(var i = 0; i < weekItem.length; i++) {
            setDisabled(true);
            let data = arrgiorni[i];
            await makeRequest.post('/calendario/getday',{data, struttura, terapisti})
            .then(async res => {
                const conto = i+1;
                let data = weekItem[i];
                const newdata = arrgiorni[i];
                let newArr = copyWeek.map((item) => {
                    if(item.Data == data)
                        return {...item, Data: newdata};
                    return item;
                })
                newArr = newArr.filter(item => item.Data == newdata);
                newArr = newArr.filter(item => checkTerapisti(terapisti, item) !== false)
                /*for(var i = 0; i < listaterapisti[0].length; i++) {
                    newArr = newArr.map((item,index)=> {
                        console.log('entro',index)
                        if(item.RifTerapista === listaterapisti[0][i].Riferimento) {
                            return item;
                        }
                    })
                }
                console.log(newArr)*/
                if(res.status === 200) {
                    const id = res.data.map((item) => {
                        return item.ID;
                    })
                    makeRequest.post('/calendario/remove', {id})
                    .then(res=> {
                        inserisciLog("Ho cancellato dal calendario terapie del "+newdata);
                        const mypromise = insertCalendario(newArr,true).then(() => {
                            setUpdate(prev => !prev)
                            setDisabled(false);
                            inserisciLog("Ho inserito nel calendario terapie in giorno "+newdata+" (Incollate da "+data+")");
                        })
                        toast.promise(mypromise, {
                            loading: 'Sto incollando... non chiudere la finestra',
                            success: (conto)+'/'+weekItem.length+' incollato con successo',
                            error: 'Si è verificato un errore, riprova'
                        });            
                    })
                    .catch(err=>toast.error("Si è verificato un errore"))
                } else if(res.status === 201) {
                    const mypromise = insertCalendario(newArr,true).then(() => {
                        setUpdate(prev => !prev)
                        setDisabled(false);
                        inserisciLog("Ho inserito nel calendario terapie in giorno "+newdata+" (Incollate da "+data+")");
                    })
                    toast.promise(mypromise, {
                        loading: 'Sto incollando... non chiudere la finestra',
                        success: (conto)+'/'+weekItem.length+' incollato con successo',
                        error: 'Si è verificato un errore, riprova'
                    });
                }
            })
            .catch(err => console.log(err));    
        }
    }
    const handlePasteDay = () => {
        setDisabled(true);
        let conto = 0;
        let newArr = copyDay.map((item) => {
            return {...item, Data: dayItem}
        })
        const terapisti = listaterapisti[0].map((item) => {
            return item.Riferimento;
        });
        newArr = newArr.filter(item=> checkTerapisti(terapisti, item) !== false)
        newArr.map((item) => {
            if(item.Regime === 0)
               conto++;
            return item;
        })
        makeRequest.post('calendario/getsettimanali', {datainizio, datafine, struttura, tipo})
        .then(res=> {
            const settimana = res.data[0].Conto;
            if(checkMaxPresenze(conto+settimana)) {//MANCA SOLO CHECK PER PRESENZE DEL PAZIENTE NUM TERAPIE SETTIMANALI
                const data = dayItem;
                const terapisti = listaterapisti[0].map((item) => {
                    return item.Riferimento;
                });
                makeRequest.post('/calendario/getday',{data, struttura, terapisti})
                .then(async res => {
                    if(res.status === 200) {
                        const id = res.data.map((item) => {
                            return item.ID;
                        })
                        makeRequest.post('/calendario/remove', {id})
                        .then(res=> {
                            const mypromise = insertCalendario(newArr).then(() => {
                                setUpdate(prev => !prev)
                                setDisabled(false);
                            })
                            showModalDay(false);
                            toast.promise(mypromise, {
                                loading: 'Sto incollando... non chiudere la finestra',
                                success: 'Incollato con successo',
                                error: 'Si è verificato un errore, riprova'
                            });            
                        })
                        .catch(err=>toast.error("Si è verificato un errore"))
                    } else if(res.status === 201) {
                        const mypromise = insertCalendario(newArr).then(() => {
                            setUpdate(prev => !prev)
                            setDisabled(false);
                        })
                        showModalDay(false);
                        toast.promise(mypromise, {
                            loading: 'Sto incollando... non chiudere la finestra',
                            success: 'Incollato con successo',
                            error: 'Si è verificato un errore, riprova'
                        });
                    }//67558
                })
                .catch(err => console.log(err));    
            } else {
                setDisabled(false);
                return toast.error('Non puoi incollare: superamento prestazioni settimanali')
            }
        })
    }
    const handleAddRed = () => {
        setDisabled(true);
        const dataswitch = dragItem?.indexOf('data');
        const oraswitch = dragItem.indexOf('ora');
        const terswitch = dragItem.indexOf('ter');
        const data = dragItem?.substring(dataswitch+4, oraswitch);
        const ora = dragItem?.substring(oraswitch+3, terswitch);
        const terapista = dragItem?.substring(terswitch+3);
        makeRequest.post('/terapisti/getterapista/',{terapista})
        .then(res => { 
            const categoria = res.data[0].Tipo;
            makeRequest.post('/calendario/checkempty', {data, ora, terapista, struttura, categoria})
            .then(res => {
                const paziente = -1;
                const datanew = new Date(data);
                const giorno = datanew.getDay();
                const stato = -1;
                const regime = 4;
                const riftipo = tipo;
                makeRequest.put('/calendario/insert',{data, ora, terapista, categoria, paziente, struttura, giorno, regime, stato, riftipo})
                .then(res => {
                    showModalAdd(false)
                    setUpdate(prev => !prev)
                    setDisabled(false);
                })
                .catch(err=>{
                    toast.error("Si è verificato un errore, riprova")
                    setDisabled(false);
                })
            })
            .catch(err=> {
                setErrore("Si è verificato un errore, riprova più tardi");
                setStartDate(datainizio);
            })
        })
        .catch(err=>console.log(err))
    }

    const handleAddGreen = () => {
        setDisabled(true);
        const dataswitch = dragItem?.indexOf('data');
        const oraswitch = dragItem.indexOf('ora');
        const terswitch = dragItem.indexOf('ter');
        const data = dragItem?.substring(dataswitch+4, oraswitch);
        const ora = dragItem?.substring(oraswitch+3, terswitch);
        const terapista = dragItem?.substring(terswitch+3);
        makeRequest.post('/terapisti/getterapista/',{terapista})
        .then(res => { 
            const categoria = res.data[0].Tipo;
            makeRequest.post('/calendario/checkempty', {data, ora, terapista, struttura, categoria})
            .then(res => {
                const paziente = -1;
                const datanew = new Date(data);
                const giorno = datanew.getDay();
                const stato = -1;
                const regime = 5;
                const riftipo = tipo;
                makeRequest.put('/calendario/insert',{data, ora, terapista, categoria, paziente, struttura, giorno, regime, stato, riftipo})
                .then(res => {
                    showModalAdd(false)
                    setUpdate(prev => !prev)
                    setDisabled(false);
                })
                .catch(err=>{
                    toast.error("Si è verificato un errore, riprova")
                    setDisabled(false);
                })
            })
            .catch(err=> {
                setErrore("Si è verificato un errore, riprova più tardi");
                setStartDate(datainizio);
            })
        })
        .catch(err=>console.log(err))
    }
    const checkMaxPresenze = (num) => {
        switch(struttura) {
            case 0: {
                switch(tipo) {
                    case 0: {
                        if(num < 1500)
                            return true;
                        break;
                    }
                    case 1: {
                        if(num < 1500)
                            return true;
                        break;
                    }
                    default: return false;
                }
            }
            case 1: {
                switch(tipo) {
                    case 0: {
                        if(num < 1000)
                            return true;
                        break;
                    }
                    case 1: {
                        if(num < 1000)
                            return true;
                        break;
                    }
                    default: return false;
                }
                break;
            }
            default: return false;
        }
        return false;
    }
    const handleAdd = () => {
        setDisabled(true);
        const id = retrieve[0]?.ID;
        makeRequest.post('/calendario/getsettimanali/',{datainizio, datafine, struttura, tipo})
        .then(res => {
            const settimana = res.data[0];
            if(checkMaxPresenze(settimana.Conto)) {
                const dataswitch = dragItem?.indexOf('data');
                const oraswitch = dragItem.indexOf('ora');
                const terswitch = dragItem.indexOf('ter');
                const data = dragItem?.substring(dataswitch+4, oraswitch);
                const ora = dragItem?.substring(oraswitch+3, terswitch);
                const terapista = dragItem?.substring(terswitch+3);
                const giornoswitch = new Date(data);
                const giorno = giornoswitch.getDay();
                let categoria = '';
                makeRequest.post('/terapisti/getterapista/',{terapista})
                .then(res => { 
                    categoria = res.data[0].Tipo;
                    let conto = 0;
                    makeRequest.post('/calendario/checkempty', {data, ora, terapista, struttura, categoria})
                    .then(res => {
                        const newArr = [
                            {Data: data, Ora: ora, RifPaziente: id, RifTerapista: terapista, Giorno: giorno, Regime: parseInt(regimeselect), Tipo: categoria, RifTipo: tipo, Struttura: struttura, Stato: 0}
                        ]
                        const mypromise = insertCalendario(newArr).then(() => {
                            setUpdate(prev => !prev);    
                            setDisabled(false);  
                        });
                        showModalAdd(false);
                        toast.promise(mypromise, {
                            loading: 'Inserisco...',
                            success: 'Paziente inserito con successo',
                            error: 'Si è verificato un errore, riprova'
                        });
                    })
                    .catch(err => {
                        setDisabled(false);
                        toast.error("Il posto selezionato non è disponibile")
                    })
                })
                .catch(err => {
                    toast.error(err);
                });
            } else {
                return toast.error('Hai raggiunto la cifra settimanale di terapie ordinarie');
            }
            
        })
        .catch(err => console.log(err))

    }
    const handleCloseAdd = () => {
        setTab(-1);
        setListaFind([]);
        setRetrieve([]);
        setFinded(false);
        showFind(false);
        setSearch("");
        showModalAdd(false);
        setInfoPaziente(false)
        setErrore('');
    }
    const [infoPaziente, setInfoPaziente] = useState(false);
    const dragHandle = (e) => {
        if(!disabled) {
            setDragItem(e.target.getAttribute('data-spec-name'))
        }
    }
    const StopDrag = (e) => {
    }
    const checkPrestazioni = (prestazione, num, idpaziente) => {
        let val = false;
        const arr = listapresenze.filter(item => item.RifPaziente === idpaziente && (item.Regime === 0 || item.Regime === 2) && item.Tipo === prestazione)
        if(arr.length>=num)
            val = false;
        else 
            val = arr.length;
        return val;
    }
    const countPrestazioni = (prestazione, num, tipo = 0) => {
        let val = false;
        const arr = listapresenze.filter(item => item.RifPaziente === infoPaziente?.ID && (item.Regime === 0 || item.Regime === 2) && item.Tipo === prestazione)
        if(arr.length === num)
            val = false;
        else 
            val = arr.length;
        if(tipo === 0)
            return val;
        else
            return arr.length;
    }
    const countFrequenza = (frequenza) => {
        let val = false;
        const arr = listapresenze.filter(item => item.RifPaziente === infoPaziente?.ID && (item.Regime === 0 || item.Regime === 2))
        switch(frequenza) {
            case 'Monosettimanale': {
                if(arr.length === 1)
                    val = true;
                break;
            }
            case 'Bisettimanale': {
                if(arr.length === 2)
                    val = true;
                break;
            }
            case 'Trisettimanale': {
                if(arr.length === 3)
                    val = true;
                break;
            }
            case 'Quadrisettimanale': {
                if(arr.length === 4)
                    val = true;
                break;
            }
            case 'Pentasettimanale': {
                if(arr.length === 5)
                    val = true;
                break;
            }
            case 'Giornaliero': {
                if(arr.length === 6)
                    val = true;
                break;
            }
            default: break;
        }
        return val;
    }
    const handleClickDay = (e) => {
        if(!disabled) {
            const val = e.target.id;
            setDayItem(val);
            showModalDay(true);
        }
    }
    const handleClickDelete = () => {
        if(!disabled) {
            const id = dragItem?.ID;
            if(window.confirm("Sei sicuro di voler eliminare questa prestazione?")) {
                if(!isNaN(id)) {
                    setDisabled(true);
                    const newArr = [dragItem];
                    const mypromise = removeCalendario(newArr).then(() => {
                        setUpdate(prev => !prev);    
                        setDisabled(false);  
                    })
                    .catch(setDisabled(false))
                    showModalModify(false);
                    toast.promise(mypromise, {
                        loading: 'Sto eliminando... non chiudere la finestra',
                        success: 'Eliminato con successo',
                        error: 'Si è verificato un errore, riprova'
                    });
                }
            }
        }
    }
    const handleClickTer = (e) => {
        if(!disabled) {
            const val = e.currentTarget.getAttribute('data-spec-name')
            const data = val.indexOf('data');
            const ter = val.indexOf('ter');
            const dataswitch = val.substring(data+4, ter);
            const terswitch = val.substring(ter+3);   
            setTerItem({Data: dataswitch, RifTerapista: terswitch});//Data, Terapista, 
            showModalTer(true);
        }
    }
    const handleClickEmpty = (e) => {
        if(!disabled) {
            setDragItem(e.currentTarget.getAttribute('data-spec-name'))
            showModalAdd(true);
        }
    }
    const handleClickFully = (e) => {
        if(!disabled) {
            const itemSelect = e.currentTarget.getAttribute('data-spec-name');
            const data = itemSelect.indexOf('data');
            const ora = itemSelect.indexOf('ora');
            const ter = itemSelect.indexOf('ter');
            const dataswitch = itemSelect.substring(data+4, ora);
            const oraswitch = itemSelect.substring(ora+3, ter);
            const terswitch = itemSelect.substring(ter+3);     
            const newarr = listapresenze.filter(item => item.Data === dataswitch && item.Ora === oraswitch && item.RifTerapista == terswitch)
            const otherarr = listapazienti.filter(item => item.ID === newarr[0].RifPaziente)
            setInfoPaziente(otherarr[0])
            setDragItem(newarr[0])
            showModalModify(true);
        }
    }
    const copyDayTer = (ter, day) => {
        const newArr = listapresenze.map((item) => {
            if(item.Data === day)
                if(item.RifTerapista === parseInt(ter))
                    return item;
        })
        setCopyTer(newArr);
    }
    const handleDrop = async(e) => {
        if(!disabled) {
            if(dragItem) {
                setDisabled(true);
                const data = dragItem.indexOf('data');
                const ora = dragItem.indexOf('ora');
                const ter = dragItem.indexOf('ter');
                const dataswitch = dragItem.substring(data+4, ora);
                const oraswitch = dragItem.substring(ora+3, ter);
                const terswitch = dragItem.substring(ter+3);
                let terapista = terswitch;
                let catprima = '';
                let nomeprima = '';
                await makeRequest.post('/terapisti/getterapista/',{terapista})
                .then(res => {
                    catprima = res.data[0]?.Tipo;
                    nomeprima = res.data[0]?.username;
                })
                let id = null;
                listapresenze.map((item) => {
                    if(item.Data === dataswitch)
                        if(item.Ora === oraswitch)
                            if(item.RifTerapista === parseInt(terswitch))
                                id = item.ID;
                })
                setDragItem(null);
                e.stopPropagation();
                e.preventDefault();
                const dragPos = e.target.getAttribute('data-spec-name');
                if(dragItem === dragPos)
                    return setDragItem(null);
                let trovato = false;
                const datafinale = dragPos.substring(data+4, ora);
                const orafinale = dragPos.substring(ora+3, ter);
                const terfinale = dragPos.substring(ter+3);
                terapista = terfinale;
                listapresenze.map((item) => {
                    if(item.Data === datafinale)
                        if(item.Ora === orafinale)
                            if(item.RifTerapista === parseInt(terfinale))
                                trovato = true;
                })
                if(trovato === true) {
                    toast.error('Il posto è già occupato da un\'altra terapia');
                    setDisabled(false);
                    return setDragItem(null);
                }
                listapresenze.map((item) => {
                    if(item.Data === dataswitch)
                        if(item.Ora === oraswitch)
                            if(item.RifTerapista === parseInt(terswitch))
                                if(item.Regime === 6)
                                    trovato = true;
                })
                if(trovato === true) {
                    toast.error('Non puoi spostare questo elemento. Puoi soltanto cancellarlo.');
                    setDisabled(false);
                    return setDragItem(null);
                }
                makeRequest.post('/terapisti/getterapista/',{terapista})
                .then(res => { 
                    const categoria = res.data[0]?.Tipo;
                    if(categoria === catprima) {
                        const data = datafinale;
                        const ora = orafinale;
                        makeRequest.post('/calendario/checkempty', {data, ora, terapista, struttura, categoria})
                        .then(res => {
                            const newArr = [
                                {Data: data, Ora: ora, ID: id, RifTerapista: terapista, Dataprima: dataswitch, Oraprima: oraswitch, Userprima: nomeprima}
                            ]
                            const mypromise = moveCalendario(newArr).then(() => {
                                setUpdate(prev => !prev);    
                                setDisabled(false);  
                            });
                            toast.promise(mypromise, {
                                loading: 'Sposto...',
                                success: 'Paziente spostato con successo',
                                error: 'Si è verificato un errore, riprova'
                            });
                        })
                        .catch(err => {
                            toast.error("Il posto selezionato non è disponibile")
                            setDisabled(false);
                        })
                    } else {
                        toast.error("Puoi spostare solo nella stessa categoria dell'attuale prestazione")
                        setDisabled(false);
                    }
                })
                .catch(err => {
                    toast.error(err);
                });
            }
        }
    }
    const YMD = (date) => {
        const val = date.split('-');
        return val[2]+'-'+val[1]+'-'+val[0];
    }
    const GetDay = (day) => {
        const date = new Date(day);
        const dw = date.getDay();
        let val = 'Errore';
        switch(dw) {
            case 0: {
                val = 'Domenica';
                break;
            }
            case 1: {
                val = 'Lunedì';
                break
            }
            case 2: {
                val = 'Martedì';
                break
            }
            case 3: {
                val = 'Mercoledì';
                break
            }
            case 4: {
                val = 'Giovedì';
                break
            }
            case 5: {
                val = 'Venerdì';
                break
            }
            case 6: {
                val = 'Sabato';
                break
            }
            default: break;
        }
        return val;
    }
    const getDayOfWeek = (d, dayweek) => {
        d = new Date(d);
        var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -7+dayweek : dayweek); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }
    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${year}-${month}-${day}`;
    };
    const handleStartDate = (e) => {
        const val = e.currentTarget.value;
        setStartDate(formatDate(getDayOfWeek(new Date(val), 1)))
        setEndDate(formatDate(getDayOfWeek(new Date(val), 6)))
    }
    const handleChangeType = (e) => {
        const val = e.currentTarget.value;
        setTipologia(val);    
    }
    const handleSaveNota = () => {
        const id = dragItem?.ID;
        const value = "Nota";
        const modify = dragItem?.Nota;
        makeRequest.put('/calendario/modify',{id, value, modify})
        .then(res=>{
            setUpdate(!update)
            toast.success("Nota salvata con successo");
        })
        .catch(err=>toast.error("Si è verificato un errore"))
    }
    const handleChangeRegime = async() => {
        let id = dragItem?.RifPaziente;
        let insert = true;
        if(dragItem?.Regime === 0) {
            id = dragItem?.ID;
            const value = "Regime";
            const modify = 1;
            makeRequest.put('/calendario/modify',{id, value, modify})
            .then(res=>{
                setUpdate(!update)
                setDragItem({...dragItem, Regime: modify})
                toast.success("Regime cambiato con successo")
            })
            .catch(err=>toast.error("Si è verificato un errore"))
        } else {
            const modify = 0;
            await makeRequest.post('/pazienti/checkexcess',{id})
            .then((res)=>{
                let value = res.data;
                switch(categoria) {
                    case 'Logopedista': {
                        insert = checkPrestazioni('Logopedista', value[0]?.ILogopedica, id);
                        if(insert === false) {
                            toast.error(value[0]?.Nome+' '+value[0]?.Cognome+': prestazioni erogate massime raggiunte per Logopedia')
                        }
                        break;
                    }
                    case 'Neuromotoria':
                    case 'Fisioterapista': {
                        insert = checkPrestazioni('Fisioterapista', value[0]?.IFKT+value[0]?.INeuromotoria, id);
                        if(insert === false) {
                            toast.error(value[0]?.Nome+' '+value[0]?.Cognome+': prestazioni erogate massime raggiunte per: Fisioterapia')
                        }
                        break;
                    }
                    case 'TNPEE': {
                        insert = checkPrestazioni('TNPEE', value[0]?.IPsicomotoria, id);
                        if(insert === false) {
                            toast.error(value[0]?.Nome+' '+value[0]?.Cognome+': prestazioni erogate massime raggiunte per: TNPEE')
                        }  
                        break;
                    }
                    default: break;
                }
                if(insert !== false) {
                    id = dragItem?.ID;
                    value = "Regime";
                    makeRequest.put('/calendario/modify',{id, value, modify})
                    .then(res=>{
                        setUpdate(!update)
                        setDragItem({...dragItem, Regime: modify})
                        toast.success("Regime cambiato con successo")
                    })
                    .catch(err=>toast.error("Si è verificato un errore"))
                }
            })
        }
    }
    const handleChangeRifTipo = (element) => {
        const id = dragItem?.ID;
        const value = "RifTipo";
        const modify = element.target.value;
        makeRequest.put('/calendario/modify',{id, value, modify})
        .then(res=>{
            setUpdate(!update)
            toast.success("Regime cambiato con successo")
        })
        .catch(err=>toast.error("Si è verificato un errore"))
    }
    const handleChangeCategoria = (e) => {
        const val = e.currentTarget.value;
        setCategoria(val);    
    }
    const updateListaSostituzioni = (arr, sostituti, sostituzioni) => {
        let newarr;
        let arrnew = [];
        for(var count = 0; count < 6; count++) {
            newarr = arr;
            newarr = newarr.map((item) => {
                for(var i = 0; i < sostituzioni?.length; i++) {
                    if(item.Riferimento === sostituzioni[i].RifTerapista) {
                        if(sostituzioni[i].DataInizio >= arrgiorni[count] && sostituzioni[i].DataFine <= arrgiorni[count]) {
                            for(var b = 0; b < sostituti?.length; b++) {
                                if(sostituti[b].ID === sostituzioni[i].RifSostituto) {
                                    return {...item, Nome: sostituti[b].Nome.replace('Dott.ssa','').replace('Dott.','')+'\r('+item.Nome+')'}
                                }
                            }
                        }
                    }
                }
                return item;
            });
            arrnew.push(newarr);
        }
        //console.log(arr, arrnew, newarr);
        setListaTerapisti(arrnew);
    }
    const getDates = () => {
        if (datainizio && datafine) {
            const start = new Date(datainizio);
            const end = new Date(datafine);
            const days = Math.floor((end - start) / (1000 * 60 * 60 * 24));
            const dates = [];
            for (let i = 0; i <= days; i++) {
                const currentDate = new Date(start.getTime() + i * 24 * 60 * 60 * 1000);
                dates.push(formatDate(currentDate));
            }
            setDateArray(dates);
        }
    };   
    const [datainizio, setStartDate] = useState(formatDate(getDayOfWeek(new Date(), 1)));
    const [datafine, setEndDate] = useState(formatDate(getDayOfWeek(new Date(), 6)));
    const [update, setUpdate] = useState(false);
    const [tipo, setTipologia] = useState(0);
    const [categoria, setCategoria] = useState("Tutti");
    const [arrgiorni, setDateArray] = useState([]);
    const arrora = ['08:00', '08:45', '09:30', '10:15', '11:00', '11:45', '12:30', '13:15', '14:00', '14:45', '15:30', '16:15', '17:00', '17:45', '18:30', '19:15']
    const [font, setFont] = useState(10);
    const [padding, setPadding] = useState(0.5)
    const [listapresenze, setListaPresenze] = useState([]);
    const [listaterapisti, setListaTerapisti] = useState([]);
    const [listapazienti, setListaPazienti] = useState([]);
    const [listasostituzioni, setListaSostituzioni] = useState([]);
    const [listasostituti, setListaSostituti] = useState([]);
    const [clickForward, setClickForward] = useState(false);
    const [clickBack, setClickBack] = useState(false);
    const [clickForwardPad, setClickForwardPad] = useState(false);
    const [clickBackPad, setClickBackPad] = useState(false);
    const [copyTer, setCopyTer] = useState([])
    const [copyDay, setCopyDay] = useState([])
    const [copyWeek, setCopyWeek] = useState([]);
    const [weekItem, setWeekItem] = useState([]);
    const [dragItem, setDragItem] = useState('');
    const [dayItem, setDayItem] = useState(0);
    const [terItem, setTerItem] = useState([]);

    useEffect(()=> {
        if(clickForward) {
            setFont(prev=> prev+1)
            setClickForward(false);
        }
        else if(clickBack) {
            setFont(prev=> prev-1)
            setClickBack(false);
        }
    },[clickForward, clickBack])
    useEffect(()=> {
        if(clickForwardPad) {
            setPadding(prev=> prev+0.1);
            setClickForwardPad(false);
        }
        else if(clickBackPad) {
            setPadding(prev=> prev-0.1);
            setClickBackPad(false);
        }
    },[clickForwardPad, clickBackPad])
    //
    useEffect(() => {
        makeRequest.get('/user/auth')
        .then(res=>{
            setCurrentUser(res.data[0])
            setStruttura(res.data[0].RifStruttura);
            setTipologia(res.data[0].RifTipo);
        })
        .catch(err => {
            if(err.response?.status === 403)
                HandleLogout(logout,navigate);
        })
        makeRequest.get('/pazienti/getpresenze')
        .then(res => setListaPazienti(res.data))
        .catch(err => console.log(err));
        makeRequest.get('/calendario/sostituti')
        .then(res => setListaSostituti(res.data))
        .catch(err => console.log(err));
    },[])
    useEffect(() => {
        setListaSostituzioni([]);
        makeRequest.post('/calendario/sostituzioni',{datainizio, datafine})
        .then(res => {
            setListaSostituzioni(res.data)
        })
        .catch(err=>console.log(err))
        getDates();
    },[datainizio, datafine])
    useEffect(() => {
        if(listasostituti?.length && listasostituzioni?.length >= 0) {
            makeRequest.post('/terapisti/getpresenze',{struttura, tipo, categoria})
            .then(res => { 
                const newarr = res.data.map((item) => {
                    const nome = item.Nome.replace('Dott.ssa', '').replace('Dott.', '');
                    return {...item, Nome: nome}
                });
                updateListaSostituzioni(newarr, listasostituti, listasostituzioni);
            })
        } else {
            getDates();     
        }
    },[arrgiorni, categoria, tipo]);
    useEffect(() => {
        setCopyDay([]);
        setCopyWeek([]);
        setWeekItem([]);
    },[categoria, tipo])
    useEffect(() => {
        makeRequest.post('/calendario/get',{datainizio, datafine, struttura, categoria})
        .then(res => { setListaPresenze(res.data);})
        .catch(err => console.log(err));
    },[datainizio, datafine, categoria, update, struttura])
    return(
        <>
        <Container fluid>
            <Row>
                <Toaster/>
                <Col style={{textAlign: 'left'}} xs={6}>
                    <Button onClick={() => setClickBack(true)}><DashLg/> Font</Button>
                </Col>
                <Col style={{textAlign: 'right'}} xs={6}>
                    <Button onClick={() => setClickForward(true)}><PlusLg/> Font</Button>
                </Col>
                <Col style={{marginTop: '1rem', textAlign: 'left'}} xs={6}>
                    <Button onClick={() => setClickBackPad(true)}><DashLg/> Space</Button>
                </Col>
                <Col style={{marginTop: '1rem', textAlign: 'right'}} xs={6}>
                    <Button onClick={() => setClickForwardPad(true)}><PlusLg/> Space</Button>
                </Col>
                <Col style={{marginTop: '1rem', textAlign: 'right'}} xs={12}>
                    <Button onClick={handleLogout} variant="danger">Logout</Button>
                </Col>
                <Col style={{marginTop: '1rem', textAlign: 'left'}} xs={6}>
                    <Button onClick={handleCopyWeek} variant="warning">Copia Settimana</Button>
                </Col>
                {copyWeek.length ? 
                    <Col style={{marginTop: '1rem', textAlign: 'right'}} xs={6}>
                        <Button disabled={disabled} onClick={handlePasteWeek} variant="success">Incolla Settimana</Button>
                    </Col>
                : 
                    <Col style={{marginTop: '1rem', textAlign: 'right'}} xs={6}></Col>
                }
                <Col style={{marginTop: '1rem'}} xs={4}>
                    <FormControl
                        type="date"
                        placeholder="Data..."
                        onChange={handleStartDate}
                    />
                </Col>
                <Col style={{marginTop: '1rem'}} xs={4}>
                    <Form.Select defaultValue={categoria} key="categoriaselezione" /*style= {{marginTop: '1em'}}*/ onChange={(e) => handleChangeCategoria(e)}>
                        <option value="Tutti">Tutti</option>
                        <option value="Logopedista">Logopedista</option>
                        <option value="TNPEE">TNPEE</option>
                        <option value="Fisioterapista">Fisioterapista</option>
                    </Form.Select>                  
                </Col>
                {currentUser?.Admin ? <Col style={{marginTop: '1rem'}} xs={4}>
                    <Form.Select defaultValue={tipo} key="tiposelezione" /*style= {{marginTop: '1em'}}*/ onChange={(e) => handleChangeType(e)}>
                        <option value="0">Ambulatoriale</option>
                        <option value="1">Domiciliare</option>
                    </Form.Select>                  
                    </Col>
                    : 
                    <Col style={{marginTop: '1rem'}} xs={4}>
                    </Col>
                }
                {(() => {
                    const arr = [];
                    const arr2 = [];
                    const arr10 = []
                    const arr11 = [];
                    for(var d = 0; d < listaterapisti.length; d++) {
                        const arr10 = []
                        arr10.push(<th style={{padding: padding+'em'}}>Orari</th>);
                        listaterapisti[d].map((item) => {
                            return arr10.push(<th onClick={(e) => handleClickTer(e)} data-spec-name={`data${arrgiorni[d]}ter${item.Riferimento}`} style={{cursor: 'pointer', padding: padding+'em'}}>{item.Nome}</th>);
                        });
                        arr2.push(arr10);
                    }
                    for(var i = 0; i < arrgiorni.length; i++) {
                        arr11.push(countPresenze(arrgiorni[i]));
                        const arr3 = [];
                        const arr4 = [];
                        for(var b = 0; b < arrora.length; b++) {
                            const arr5 = [];
                            arr5.push(<td className={(arrora[b] < '14:00' ? 'verdechiaro' : 'rossochiaro')} style={{padding: padding+'em'}}>{arrora[b]}</td>);
                            for(var c = 0; c < listaterapisti[i]?.length; c++) {
                                let trovato = false;
                                listapresenze.map((item) => {
                                    if(item.Data === arrgiorni[i]) {
                                        if(item.Ora === arrora[b]) {
                                            if(item.RifTerapista === listaterapisti[i][c].Riferimento) {
                                                trovato = true;
                                                let nome = '';
                                                let cognome = '';
                                                listapazienti.map((paz) => {
                                                    if(paz.ID === item.RifPaziente) {
                                                        nome = paz.Nome;
                                                        cognome = paz.Cognome;
                                                    }
                                                    return false;
                                                })
                                                return arr5.push(<td onClick={(e) => handleClickFully(e)} style={{cursor: 'pointer', padding: padding+'em'}} id={item.ID} data-spec-name={`data${item.Data}ora${item.Ora}ter${item.RifTerapista}`} onDrop={handleDrop} onDragOver={(e) => e.preventDefault()} draggable onDragStart={dragHandle} onDragEnd={StopDrag} className={`${item.Regime == 0 || item.Regime == 2 ? 'azzurrino' : (item.Regime == 1 || item.Regime == 3) ? 'aranciosfondo' : (item.Regime == 4) ? 'rossochiaro' : (item.Regime == 5) ? 'verdechiaro' : (item.Regime == 6) ? 'grigiosfondo' : ''} ${item.Regime < 4 && item.RifTipo ? 'domiciliare' : ''} ${item.Regime < 4 && !item.RifTipo ? 'ambulatoriale' : ''} ${item.Nota?.length > 0 ? 'nota' : ''}`.trim()}>{(item.Regime < 4 || item.Regime > 5) ? `${nome[0]}. ${cognome}` : null}</td>)
                                            }
                                        }
                                    }
                                    return item;
                                });
                                if(!trovato)
                                    arr5.push(<td onClick={(e) => handleClickEmpty(e)} style={{cursor: 'pointer', padding: padding+'em'}} data-spec-name={`data${arrgiorni[i]}ora${arrora[b]}ter${listaterapisti[i][c].Riferimento}`} onDragOver={(e) => e.preventDefault()} onDrop={handleDrop}></td>);         
                            }
                            arr4.push(<tr>{arr5}</tr>);
                        }
                        arr3.push(<thead><tr>{arr2[i]}</tr></thead>);
                        arr3.push(<tbody>{arr4}</tbody>);
                        arr.push(<Col style={{overflow: 'scroll', textAlign: 'center'}} xs={12} md={4}><h3 id={arrgiorni[i]} onClick={(e) => handleClickDay(e)} style={{cursor: 'pointer'}}>{GetDay(arrgiorni[i])}<br/>{YMD(arrgiorni[i])}<br/><span id={arrgiorni[i]} className='azzurro'>A{arr11[i].ambord}-D{arr11[i].domord}</span> | <span id={arrgiorni[i]} className="arancio">A{arr11[i].ambrp}-D{arr11[i].domrp}</span></h3><Table style={{fontSize: font, fontWeight: 'bold'}} bordered>{arr3}</Table></Col>);
                    }
                    //console.log(arr2);
                    return arr;
                })()}  
            </Row>
        </Container>
        <Modal
            show={modalAdd}
            onHide={handleCloseAdd}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Aggiungi Prestazione
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormControl
                    onFocus={() => showFind(true)}
                    onBlur={() => setTimeout(() => {showFind(false)},200)}
                    type="text" 
                    placeholder="Cerca paziente..."
                    onChange={(e) => findPaziente(e)}
                    onKeyDown={handleKey}
                />
                {find ? <Find tab={tab} rows={listaFind} show={setFinded}/> : null}
                {retrieve?.length > 0 ? 
                <>
                    <h1 style={{marginTop: '1rem'}}>{retrieve[0]?.Nome} {retrieve[0]?.Cognome}</h1>
                    <p>
                        Frequenza Erogata: <b style={{color: (countFrequenza(retrieve[0]?.FrequenzaErogata)) ? 'green' : 'red'}}>{retrieve[0]?.FrequenzaErogata}</b>
                    </p>
                    <Form.Select defaultValue={regimeselect} key="selregime" /*style= {{marginTop: '1em'}}*/ onChange={(e) => setRegime(e.currentTarget.value)}>
                        <option value="0">Ordinario</option>
                        <option value="1">Recupero Prestazione</option>
                    </Form.Select>
                    <Table>
                        <thead>
                            <tr>
                                <th>LogoA</th>
                                <th>FisioA</th>
                                <th>TnpeeA</th>
                                <th>LogoE</th>
                                <th>FisioE</th>
                                <th>TnpeeE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{textAlign: 'center'}}>
                                <td>{retrieve[0]?.Logopedica}</td>
                                <td>{retrieve[0]?.Neuromotoria+retrieve[0]?.FKT}</td>
                                <td>{retrieve[0]?.Psicomotoria}</td>
                                <td><b style={{color: (countPrestazioni("Logopedista", retrieve[0]?.ILogopedica) === false) ? 'green': 'red'}}>{retrieve[0]?.ILogopedica}</b></td>
                                <td><b style={{color: (countPrestazioni("Fisioterapista", retrieve[0]?.INeuromotoria+retrieve[0]?.IFKT) === false)? 'green': 'red'}}>{retrieve[0]?.INeuromotoria+retrieve[0]?.IFKT}</b></td>
                                <td><b style={{color: (countPrestazioni("TNPEE", retrieve[0]?.IPsicomotoria) === false) ? 'green': 'red'}}>{retrieve[0]?.IPsicomotoria}</b></td>
                            </tr>
                        </tbody>
                    </Table>
                    {countFrequenza(retrieve[0]?.FrequenzaErogata) ? null :  (<>
                            <p>Devi ancora assegnare:</p>
                                {countPrestazioni("Logopedista", retrieve[0]?.ILogopedica === false) ? null : <p><span><b>{parseInt(retrieve[0]?.ILogopedica)-parseInt(countPrestazioni("Logopedista", retrieve[0]?.ILogopedica,1))}</b> prestazione/i di <b>Logopedia</b></span></p>}
                                {countPrestazioni("Fisioterapista", retrieve[0]?.INeuromotoria+retrieve[0]?.IFKT === false) ? null : <p><span><b>{parseInt(retrieve[0]?.INeuromotoria+retrieve[0]?.IFKT)-parseInt(countPrestazioni("Fisioterapista", retrieve[0]?.INeuromotoria+retrieve[0]?.IFKT,1))}</b> prestazione/i di <b>Fisioterapia</b></span></p>}
                                {countPrestazioni("TNPEE", retrieve[0]?.IPsicomotoria === false) ? null : <p><span><b>{parseInt(retrieve[0]?.IPsicomotoria)-parseInt(countPrestazioni("TNPEE", retrieve[0]?.IPsicomotoria,1))}</b> prestazione/i di <b>TNPEE</b></span></p>}
                    </>)
                    }
                    {errore.length ? <span style={{color: 'red', fontWeight: 'bold'}}>{errore}</span> : null}
                </>
                : 
                <>
                    <div className="text-center">
                        <Button style={{margin: '1rem'}} variant="success" onClick={handleAddGreen}>Giorno Verde</Button>
                        <Button style={{margin: '1rem'}} variant="danger" onClick={handleAddRed}>Giorno Rosso</Button>
                        {errore.length ? <span style={{color: 'red', fontWeight: 'bold'}}>{errore}</span> : null}
                    </div>
                </>
                }
                 
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleAdd} disabled={retrieve?.length <= 0} variant="success">Aggiungi</Button>
                <Button onClick={handleCloseAdd} variant="secondary">Annulla</Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={modalModify}
            onHide={() => showModalModify(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modifica Prestazione
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(dragItem?.Regime != 4 && dragItem?.Regime != 5 ? <>
                <h4>{infoPaziente?.Nome} {infoPaziente?.Cognome}</h4>
                <p>
                    Frequenza Erogata: <b style={{color: (countFrequenza(infoPaziente?.FrequenzaErogata)) ? 'green' : 'red'}}>{infoPaziente?.FrequenzaErogata}</b>
                </p>
                <Table>
                    <thead>
                        <tr>
                            <th>LogoA</th>
                            <th>FisioA</th>
                            <th>TnpeeA</th>
                            <th>LogoE</th>
                            <th>FisioE</th>
                            <th>TnpeeE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{textAlign: 'center'}}>
                            <td>{infoPaziente?.Logopedica}</td>
                            <td>{infoPaziente?.Neuromotoria+infoPaziente?.FKT}</td>
                            <td>{infoPaziente?.Psicomotoria}</td>
                            <td><b style={{color: (countPrestazioni("Logopedista", infoPaziente?.ILogopedica) === false) ? 'green': 'red'}}>{infoPaziente?.ILogopedica}</b></td>
                            <td><b style={{color: (countPrestazioni("Fisioterapista", infoPaziente?.INeuromotoria+infoPaziente?.IFKT) === false)? 'green': 'red'}}>{infoPaziente?.INeuromotoria+infoPaziente?.IFKT}</b></td>
                            <td><b style={{color: (countPrestazioni("TNPEE", infoPaziente?.IPsicomotoria) === false) ? 'green': 'red'}}>{infoPaziente?.IPsicomotoria}</b></td>
                        </tr>
                    </tbody>
                </Table>
                {countFrequenza(infoPaziente?.FrequenzaErogata) ? null :  (<>
                        <p>Devi ancora assegnare:</p>
                            {countPrestazioni("Logopedista", infoPaziente?.ILogopedica === false) ? null : <p><span><b>{parseInt(infoPaziente?.ILogopedica)-parseInt(countPrestazioni("Logopedista", infoPaziente?.ILogopedica, 1))}</b> prestazione/i di <b>Logopedia</b></span></p>}
                            {countPrestazioni("Fisioterapista", infoPaziente?.INeuromotoria+infoPaziente?.IFKT === false) ? null : <p><span><b>{parseInt(infoPaziente?.INeuromotoria+infoPaziente?.IFKT)-parseInt(countPrestazioni("Fisioterapista", infoPaziente?.INeuromotoria+infoPaziente?.IFKT, 1))}</b> prestazione/i di <b>Fisioterapia</b></span></p>}
                            {countPrestazioni("TNPEE", infoPaziente?.IPsicomotoria === false) ? null : <p><span><b>{parseInt(infoPaziente?.IPsicomotoria)-parseInt(countPrestazioni("TNPEE", infoPaziente?.IPsicomotoria, 1))}</b> prestazione/i di <b>TNPEE</b></span></p>}
                   </>)
                } 
                </> : null )}
                <Button onClick={handleClickDelete} variant="danger" style={{margin: '0.5rem'}}>Elimina</Button>
                {dragItem?.Regime < 4 ? 
                <>
                    <Container>
                        <Row>
                            <Col xs={5}>
                                <Form.Select onChange={handleChangeRifTipo} style={{marginTop: '0.5rem'}}defaultValue={dragItem?.RifTipo}>
                                    <option value="0">Ambulatoriale</option>
                                    <option value="1">Domiciliare</option>
                                </Form.Select>
                            </Col>
                            <Col xs={5}>
                                <Button onClick={handleChangeRegime} className={dragItem?.Regime === 0 ? 'azzurrino regime' : 'aranciosfondo regime'} style={{margin: '0.5rem'}}>{dragItem?.Regime === 0 ? 'Ordinario' : 'R.P.'}</Button>
                            </Col>
                        </Row>
                    </Container>
                    <FloatingLabel label={"nota"}>
                        <FormControl as="textarea"
                            placeholder="Nota"
                            value={dragItem?.Nota}
                            style={{margin: '0.5rem'}}
                            onChange={(e) => setDragItem({...dragItem, Nota: e.target.value})}
                        />
                    </FloatingLabel>
                    {dragItem?.Regime < 4 ? <Button onClick={handleSaveNota} variant="secondary" style={{margin: '0.5rem'}}>Salva Nota</Button> : null}
                </>
                : null
                }
                 
            </Modal.Body>
        </Modal>
        <Modal
            show={modalDay}
            onHide={() => showModalDay(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Giornata
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <Button onClick={handleEmptyDay} style={{margin: '1rem'}} variant="danger">Svuota</Button>
                    <Button onClick={handleCopyDay} style={{margin: '1rem'}} variant="warning">Copia</Button>
                    {copyDay.length > 0 ? <Button onClick={handlePasteDay} style={{margin: '1rem'}} variant="primary">Incolla</Button> : null}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => showModalDay(false)}>Chiudi</Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={modalTer}
            onHide={() => showModalTer(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Terapista
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <Button onClick={handleEmptyTer} style={{margin: '1rem'}} variant="danger">Svuota</Button>
                    <Button onClick={handleCopyTer} style={{margin: '1rem'}} variant="warning">Copia</Button>
                    {copyTer.length > 0 ? <Button onClick={handlePasteTer} style={{margin: '1rem'}} variant="primary">Incolla</Button> : null}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => showModalTer(false)}>Chiudi</Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
export default Calendario;