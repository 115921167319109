import React, { useContext } from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import './App.css';
import Calendario from './Component/Calendario/Calendario';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthContext } from './Component/Auth/authContext';
import Login from './Component/Login/Login';
function App() {
  const { currentUser } = useContext(AuthContext);
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={currentUser !== null ? <Navigate to="/calendario"/>:<Login/>}></Route>
          <Route path="/calendario" element={currentUser === null ? <Navigate to="/"/>:<Calendario/>}></Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;