import React, { useState, useContext } from 'react';
import { Container, Row, Col, Form, Button, Image, FloatingLabel } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../Auth/authContext';
function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [pass, setPass] = useState(''); 
    const [userRed, setUserRed] = useState(false);
    const [passRed, setPassRed] = useState(false);
    const { logins } = useContext(AuthContext);
    const handleLogin = async(event) => {
        event.preventDefault();
        if(username.length > 3) {
            if(pass.length > 3) {
                try {
                    await logins(username, pass)
                    navigate('/')
                }
                catch(err) {
                    toast.error("Hai inserito dei dati errati o non sei autorizzato, riprova.")
                    setPassRed(true);
                    setUserRed(true);
                }
            } else {
                setPassRed(true);
                toast.error("Inserisci una password valida (min. 4 caratteri)")
            }
        } else {
            setUserRed(true);
            toast.error("Inserisci un username valido (min. 4 caratteri)")
        }
    }
    return(
            <div className='bodycolor'>
                <Image
                    src='logo.png'
                    fluid
                />
                <Toaster/>
                <Container className="align-items-center justify-content-center d-flex login">
                    <Row style={{width: '70%'}}>
                        <Col className="insideLogin text-center"  xs={12} md={{span:8, offset:2}}>
                            <Form>
                                <FloatingLabel                            
                                    label={<><img src="./person.svg"/><span> Username</span></>}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Username"
                                        onChange={(e) => {
                                            setUsername(e.target.value)
                                            if(e.target.value.length > 3)
                                                setUserRed(false);
                                                else if(e.target.value.length > 0)
                                                setUserRed(true);
                                            else if(e.target.value.length === 0)
                                                setUserRed(false);
                                        }}
                                        className={`mb-3 ${userRed ? 'redborder' : null}`}
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    label={<><img src="./lock.svg"/><span> Password</span></>}
                                >
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        onChange={(e) => {
                                            setPass(e.target.value)
                                            if(e.target.value.length > 3)
                                                setPassRed(false);
                                            else if(e.target.value.length > 0)
                                                setPassRed(true);
                                            else if(e.target.value.length === 0)
                                                setPassRed(false);
                                        }}
                                        className={`mb-3 ${passRed ? 'redborder' : null}`}
                                    />
                                </FloatingLabel>
                                <div className="d-grid gap-2">
                                    <Button type="submit" size="lg" onClick={handleLogin} className='loginbutton'>Login</Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
    )
}

export default Login;